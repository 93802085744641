import React from "react";
import { Link } from "gatsby";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Covers = () => {
  return (
    <div>
      <Layout>
        <Head title="Covers" />
        <h2>Covers</h2>
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1557205418/covers/1.jpg"
          alt="Benji Friedman Photography, Car Covers Series"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1557205418/covers/2.jpg"
          alt="Benji Friedman Photography, Car Covers Series"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1557205418/covers/3.jpg"
          alt="Benji Friedman Photography, Car Covers Series"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1557205418/covers/4.jpg"
          alt="Benji Friedman Photography, Car Covers Series"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1557205418/covers/5.jpg"
          alt="Benji Friedman Photography, Car Covers Series"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1557205418/covers/6.jpg"
          alt="Benji Friedman Photography, Car Covers Series"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1557205418/covers/7.jpg"
          alt="Benji Friedman Photography, Car Covers Series"
        />
        <Link to="/media">
          <h3>Media</h3>
        </Link>
      </Layout>
    </div>
  );
};

export default Covers;
